import { FleamarketArticleStatus } from "__generated__/jampot/alpha-kr";
import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { isEmpty } from "lodash";
import { z } from "zod";

import type { LdJson } from "./common";
import { priceCurrencyMap } from "./common";

/**
 * @see https://schema.org/ItemAvailability
 */
const buySellItemAvailabilityMap = {
  [FleamarketArticleStatus.Ongoing]: "https://schema.org/InStock",
  [FleamarketArticleStatus.Reserved]: "https://schema.org/OutOfStock",
  [FleamarketArticleStatus.Closed]: "https://schema.org/OutOfStock",
};

const buySellDetailLdJsonSchema = z.object({
  name: z.string(),
  description: z.string(),
  image: z.string(),
  url: z.string(),
  price: z.string().nullish(),
  availability: z.nativeEnum(FleamarketArticleStatus),
  sellerName: z.string().nullish(),
});

type BuySellItemLdJson = z.infer<typeof buySellDetailLdJsonSchema>;

export const buySellDetailPageSchema = (data: unknown) => {
  const parseResult = buySellDetailLdJsonSchema.safeParse(data);

  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
};

const buySellItemJsonLd = (
  {
    name,
    description,
    image,
    url,
    price,
    availability,
    sellerName,
  }: BuySellItemLdJson,
  karrotLocalCountryCode: KarrotLocalCountryCode,
) => ({
  "script:ld+json": {
    "@context": "https://schema.org",
    "@type": "Product",
    name,
    description,
    image,
    url,
    offers: {
      "@type": "Offer",
      price: price ?? "0",
      priceCurrency: priceCurrencyMap[karrotLocalCountryCode],
      itemCondition: "https://schema.org/UsedCondition",
      availability: buySellItemAvailabilityMap[availability],
      seller: {
        "@type": "Person",
        name: sellerName ?? "",
      },
    },
  },
});

export const buySellDetailPage = (
  item: BuySellItemLdJson,
  karrotLocalCountryCode: KarrotLocalCountryCode,
): LdJson[] => [buySellItemJsonLd(item, karrotLocalCountryCode)];

export const buySellListPageSchema = (data: unknown[]) => {
  const parseResult = data.flatMap((item) => {
    const result = buySellDetailLdJsonSchema.safeParse(item);

    if (result.success) {
      return [result.data];
    }

    return [];
  });

  if (isEmpty(parseResult)) {
    return null;
  }

  return parseResult;
};

type BuySellPageLdJsonParams = {
  items: BuySellItemLdJson[];
  countryCode: KarrotLocalCountryCode;
};

export const buySellAllPage = ({
  items,
  countryCode,
}: BuySellPageLdJsonParams): LdJson => ({
  "script:ld+json": {
    "@context": "https://schema.org",
    "@type": "ItemList",
    numberOfItems: items.length,
    itemListElement: items.map((item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: buySellItemJsonLd(item, countryCode)["script:ld+json"],
    })),
  },
});
