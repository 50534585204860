import { SalaryType } from "__generated__/jampot/alpha-kr";
import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { isEmpty, isString, pickBy } from "lodash";
import { z } from "zod";

import { krServiceHomeUrl } from "~/site-urls";

import type { LdJson, LdJsonObject } from "./common";
import { addressSchema, getAddress, priceCurrencyMap } from "./common";

const salaryTypeTextMap = {
  [SalaryType.Daily]: "DAY",
  [SalaryType.Hourly]: "HOUR",
  [SalaryType.Monthly]: "MONTH",
  [SalaryType.PerCase]: "건당",
};

const jobDetailLdJsonSchema = z.object({
  title: z.string(),
  description: z.string(),
  datePosted: z.string().nullable(),
  workplaceCompanyName: z.string(),
  author: z.string().nullable(),
  address: addressSchema,
  salaryType: z.nativeEnum(SalaryType),
  salary: z.number(),
  workHours: z.string(),
  url: z.string(),
});

type JobDetailLdJsonSchema = z.infer<typeof jobDetailLdJsonSchema>;

export const jobDetailPageSchema = (data: unknown) => {
  const parseResult = jobDetailLdJsonSchema.safeParse(data);

  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
};

const jobItemJsonLd = (
  item: JobDetailLdJsonSchema,
  countryCode: KarrotLocalCountryCode,
): LdJson => {
  const ldJson: LdJsonObject = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    title: item.title,
    description: item.description,
    /**
     * @description Type을 위해 toISOString()을 사용했어요. 게시일이 없을 경우 노출하지 않는다는 것을 전제로 해요.
     */
    datePosted: item.datePosted ?? new Date().toISOString(),
    url: item.url,
    employmentType: "파트 타임",
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: priceCurrencyMap[countryCode],
      value: {
        "@type": "QuantitativeValue",
        unitText: salaryTypeTextMap[item.salaryType],
        value: item.salary,
      },
    },
    workHours: item.workHours,
    hiringOrganization: {
      "@type": "Organization",
      name: item.workplaceCompanyName,
    },
  };

  const jobLocation = pickBy(
    {
      streetAddress: item.address.streetAddress,
      addressLocality: item.address.addressLocality,
      addressRegion: item.address.addressRegion,
    },
    isString,
  );

  if (!isEmpty(jobLocation)) {
    ldJson.jobLocation = {
      "@type": "Place",
      address: getAddress(jobLocation, KarrotLocalCountryCode.KR),
    };
  }

  return {
    "script:ld+json": ldJson,
  };
};

export const jobDetailPage = (
  item: JobDetailLdJsonSchema,
  countryCode: KarrotLocalCountryCode,
  origin: string,
): LdJson[] => {
  const result: LdJson[] = [
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "당근 알바",
            item: `${origin}${krServiceHomeUrl.JOBS}`,
          },
          {
            "@type": "ListItem",
            position: 2,
            name: item.title,
          },
        ],
      },
    },
  ];

  const workplaceCompanyName = item.workplaceCompanyName;

  if (typeof workplaceCompanyName === "string") {
    result.push(
      jobItemJsonLd(
        {
          ...item,
          workplaceCompanyName,
        },
        countryCode,
      ),
    );
  }

  return result;
};

export const jobListPageSchema = (data: unknown[]) => {
  const parseResult = data.flatMap((item) => {
    const result = jobDetailLdJsonSchema.safeParse(item);

    if (result.success) {
      return [result.data];
    }

    return [];
  });

  if (isEmpty(parseResult)) {
    return null;
  }

  return parseResult;
};

type JobAllPageLdJsonParams = {
  items: JobDetailLdJsonSchema[];
  countryCode: KarrotLocalCountryCode;
};
export const jobListPage = ({
  items,
  countryCode,
}: JobAllPageLdJsonParams): LdJson => {
  return {
    "script:ld+json": {
      "@context": "https://schema.org",
      "@type": "ItemList",
      numberOfItems: items.length,
      itemListElement: items.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: jobItemJsonLd(item, countryCode)["script:ld+json"],
      })),
    },
  };
};
