import { words } from "lodash";
import { z } from "zod";

export const businessPostLdJsonSchema = z.object({
  url: z.string(),
  title: z.string(),
  content: z.string(),
  datePublished: z.string(),
  dateModified: z.string().optional(),
  author: z.string(),
  localProfileName: z.string(),
  image: z.string().optional(),
  comments: z.array(
    z.object({
      author: z.string(),
      datePublished: z.string(),
      description: z.string(),
    }),
  ),
});

type BusinessPostLdJson = z.infer<typeof businessPostLdJsonSchema>;

export const businessPostPageSchema = (data: unknown) => {
  const parseResult = businessPostLdJsonSchema.safeParse(data);

  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
};

/**
 * NOTE: Post type은 의미가 없어서 [페이월 컨텐츠](https://developers.google.com/search/docs/appearance/structured-data/paywalled-content?hl=ko)로 다룹니다.
 * @see daangn.slack.com/archives/C02RQ8GPQ0H/p1736400035031319
 */
export const businessPost = (params: BusinessPostLdJson) => {
  const [description] = params.content.split(".");

  return {
    "script:ld+json": {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: params.url,
      headline: params.title,
      name: params.title,
      description,
      datePublished: params.datePublished,
      dateModified: "",
      author: {
        "@type": "Person",
        name: params.author,
      },
      publisher: {
        "@type": "Organization",
        name: params.localProfileName,
      },
      image: {
        "@type": "ImageObject",
        url: params.image,
      },
      url: params.url,
      commentCount: params.comments.length,
      comment: params.comments.map((comment) => ({
        "@type": "Comment",
        dateCreated: comment.datePublished,
        description: comment.description,
        author: {
          "@type": "Person",
          name: comment.author,
        },
      })),
      wordCount: words(params.content).length,
    },
  };
};
