import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { isRecordType } from "@karrotmarket-com/type-utility";
import { entries, get, isEmpty, isNil, max, min, repeat } from "lodash";
import { z } from "zod";

import { krServiceHomeUrl } from "~/site-urls";

import type { LdJson, LdJsonObject } from "./common";
import {
  addressSchema,
  geoSchema,
  getAddress,
  getAggregateRating,
  getGeo,
} from "./common";

const businessProfileBusinessDayBreakHoursSchema = z.object({
  from: z.string(), // 영업 시작 시간
  to: z.string(), // 영업 종료 시간
});

const businessProfileOperationInfoSchema = z.object({
  breakHours: businessProfileBusinessDayBreakHoursSchema.nullish(), // 휴식 시작/종료 시간
  hours: businessProfileBusinessDayBreakHoursSchema.nullish(), // 영업 시작/종료 시간
  open24Hours: z.boolean(), // 24시간 영업점인지에 대한 정보
});

const businessProfileBusinessDaySchema =
  businessProfileOperationInfoSchema.extend({
    breakHours: businessProfileBusinessDayBreakHoursSchema.nullish(), // 휴식 시작/종료 시간
    closed: z.boolean(), // 영업 종료 상태인지에 대한 정보
    hours: businessProfileBusinessDayBreakHoursSchema.nullish(), // 영업 시작/종료 시간
    open24Hours: z.boolean(), // 24시간 영업점인지에 대한 정보
  });

const businessProfileWeeklyBusinessDaysSchema = z.object({
  monday: businessProfileBusinessDaySchema,
  tuesday: businessProfileBusinessDaySchema,
  wednesday: businessProfileBusinessDaySchema,
  thursday: businessProfileBusinessDaySchema,
  friday: businessProfileBusinessDaySchema,
  saturday: businessProfileBusinessDaySchema,
  sunday: businessProfileBusinessDaySchema,
});

const localProfileDetailLdJsonSchema = z.object({
  name: z.string(),
  image: z.array(z.string()),
  address: addressSchema,
  geo: geoSchema,
  url: z.string(),
  telephone: z.string().nullable(),
  businessDays: businessProfileWeeklyBusinessDaysSchema.nullish(),
  reviews: z.array(
    z.object({
      userName: z.string().optional(),
      datePublished: z.string(),
      body: z.string().optional(),
      aspect: z.array(z.string()),
      rating: z.number(),
    }),
  ),
  aggregateRating: z.object({
    value: z.number(),
    reviewCount: z.number(),
  }),
  prices: z.array(z.number()),
});

export const localProfileDetailPageSchema = (data: unknown) => {
  const parseResult = localProfileDetailLdJsonSchema.safeParse(data);

  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
};

type LocalProfileDetailLdJson = z.infer<typeof localProfileDetailLdJsonSchema>;

export const localProfileDetailPage = (
  name: string,
  item: unknown,
  origin: string,
): LdJson[] => {
  const result: LdJson[] = [
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "당근 동네 업체",
            item: `${origin}${krServiceHomeUrl.LOCAL_PROFILE}`,
          },
          {
            "@type": "ListItem",
            position: 2,
            name,
          },
        ],
      },
    },
  ];

  const validateResult = localProfileDetailPageSchema(item);

  if (isNil(validateResult)) {
    return result;
  }

  const ldJson: LdJsonObject = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    image: validateResult.image,
    name: validateResult.name,
    address: getAddress(validateResult.address, KarrotLocalCountryCode.KR),
    url: validateResult.url,
  };

  const optionalProperties = {
    geo: getGeo(validateResult.geo),
    /**
     * ld+json 형식에서는 여러 개의 `review`가 있을 때 반드시 `aggregateRating`이 필요해요.
     * 당근 동네업체는 "이웃 후기가 10개 미만일 경우 별점을 노출하지 않는다"는 정책이 있어서,
     * 후기가 있더라도 평점이 0점일 수 있어요. 이런 경우에는 `review`를 노출하지 않도록 했어요.
     * @see https://www.notion.so/daangn/PRD-rating-system-703fd227e1bf46598f84d1367d3632aa?pvs=4
     */
    review:
      validateResult.aggregateRating.value > 0
        ? getReview(validateResult.reviews)
        : null,
    priceRange: getPriceRange(validateResult.prices),
    openingHoursSpecification: getOpeningHoursSpecification(
      validateResult.businessDays,
    ),
    aggregateRating: getAggregateRating(validateResult.aggregateRating),
    telephone: validateResult.telephone,
  };

  for (const [key, value] of entries(optionalProperties)) {
    if (!isNil(value)) {
      ldJson[key] = value;
    }
  }

  result.push({
    "script:ld+json": ldJson,
  });

  return result;
};

const localProfileListItemLdJsonSchema = z.object({
  name: z.string(),
  description: z.string(),
  image: z.string(),
  url: z.string(),
  aggregateRating: z.object({
    value: z.number(),
    reviewCount: z.number(),
  }),
  category: z.string().optional(),
  address: addressSchema.required(),
});

export const localProfileListPageSchema = (data: unknown[]) => {
  const parseResult = data.flatMap((item) => {
    const result = localProfileListItemLdJsonSchema.safeParse(item);

    if (result.success) {
      return [result.data];
    }

    return [];
  });

  if (isEmpty(parseResult)) {
    return null;
  }

  return parseResult;
};

type LocalProfileListPageLdJsonParams = {
  items: z.infer<typeof localProfileListItemLdJsonSchema>[];
};

export const localProfileListPage = ({
  items,
}: LocalProfileListPageLdJsonParams): LdJson => {
  return {
    "script:ld+json": {
      "@context": "https://schema.org",
      "@type": "ItemList",
      numberOfItems: items.length,
      itemListElement: items.map((item, index) => {
        const ldJson: LdJsonObject = {
          "@type": "ListItem",
          position: index + 1,
          item: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: item.name,
            description: item.description,
            image: item.image,
            url: item.url,
            address: getAddress(item.address, KarrotLocalCountryCode.KR),
          },
        };

        const optionalProperties = {
          aggregateRating: getAggregateRating(item.aggregateRating),
          category: item.category,
        };

        for (const [key, value] of entries(optionalProperties)) {
          if (!isNil(value) && isRecordType(ldJson.item)) {
            ldJson.item[key] = value;
          }
        }

        return ldJson;
      }),
    },
  };
};

const getReview = (reviews: LocalProfileDetailLdJson["reviews"]) => {
  const result = reviews.flatMap((review) => {
    if (typeof review.userName !== "string") {
      return [];
    }

    if (review.rating === 0) {
      return [];
    }

    const ldJson: LdJsonObject = {
      "@type": "Review",
      author: {
        "@type": "Person",
        name: review.userName,
      },
      datePublished: review.datePublished,
      reviewRating: {
        "@type": "Rating",
        ratingValue: review.rating ?? 0,
      },
    };

    if (typeof review.body === "string" && review.body?.length > 0) {
      ldJson.reviewBody = review.body;
    }

    if (Array.isArray(review.aspect) && review.aspect.length > 0) {
      ldJson.reviewAspect = review.aspect;
    }

    return [ldJson];
  });

  return isEmpty(result) ? null : result;
};

const getPriceRange = (prices: LocalProfileDetailLdJson["prices"]) => {
  if (isEmpty(prices)) {
    return null;
  }

  if (prices.length === 1) {
    /**
     * @see https://schema.org/priceRange
     */
    return repeat("₩", prices[0].toString().length);
  }

  return `₩${min(prices)}~${max(prices)}`;
};

const daysSchemaMap = {
  monday: "https://schema.org/Monday",
  tuesday: "https://schema.org/Tuesday",
  wednesday: "https://schema.org/Wednesday",
  thursday: "https://schema.org/Thursday",
  friday: "https://schema.org/Friday",
  saturday: "https://schema.org/Saturday",
  sunday: "https://schema.org/Sunday",
};

const getOpeningHoursSpecification = (
  businessDays: LocalProfileDetailLdJson["businessDays"],
) => {
  if (isNil(businessDays)) {
    return null;
  }

  const specification = entries(businessDays).flatMap(([days, detail]) => {
    if (typeof detail === "string") {
      return [];
    }

    if (isNil(detail.hours)) {
      return [];
    }

    const daysSchema = get(daysSchemaMap, days);

    if (typeof daysSchema === "string") {
      return [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: daysSchema,
          opens: detail.hours.from,
          closes: detail.hours.to,
        },
      ];
    }

    return [];
  });

  return isEmpty(specification) ? null : specification;
};
