/**
 * NOTE: 임시 코드
 * FIXME: 삭제 필요
 * @description Jampot에서 union type을 사용할 수 없어서 trades의 타입을 구분하기 위한 임시 타입가드.
 * Union type을 사용할 수 있게되면 해당 코드는 삭제해야 함.
 */
import type { Maybe } from "__generated__/jampot/alpha-kr";
import { ArticleTradeType } from "__generated__/jampot/alpha-kr";
import { isRecordType } from "@karrotmarket-com/type-utility";

type BorrowTrade = {
  preffered: boolean;
  adjustable: boolean;
  deposit: number;
  description?: Maybe<string>;
  manageCost?: Maybe<number>;
  manageCostDescription?: Maybe<string>;
};
export const isBorrowType = (trade: unknown): trade is BorrowTrade =>
  isRecordType(trade) && trade.type === ArticleTradeType.Borrow;

type BuyTrade = {
  preferred: boolean;
  price: number;
  manageCost?: Maybe<number>;
  manageCostDescription?: Maybe<string>;
};
export const isBuyType = (trade: unknown): trade is BuyTrade =>
  isRecordType(trade) && trade.type === ArticleTradeType.Buy;

type MonthTrade = {
  preferred: boolean;
  manageCost?: Maybe<number>;
  manageCostDescription?: Maybe<string>;
  adjustable: boolean;
  deposit: number;
  description?: Maybe<string>;
  monthlyPay: number;
};
export const isMonthType = (trade: unknown): trade is MonthTrade =>
  isRecordType(trade) && trade.type === ArticleTradeType.Month;

type ShortTrade = {
  preferred: boolean;
  deposit: number;
  description?: Maybe<string>;
  monthlyPay: number;
  manageCost?: Maybe<number>;
  manageCostDescription?: Maybe<string>;
};
export const isShortTrade = (trade: unknown): trade is ShortTrade =>
  isRecordType(trade) && trade.type === ArticleTradeType.Short;

type YearTrade = {
  preferred: boolean;
  adjustable: boolean;
  deposit: number;
  description?: Maybe<string>;
  yearlyPay: number;
  manageCost?: Maybe<number>;
  manageCostDescription?: Maybe<string>;
};
export const isYearType = (trade: unknown): trade is YearTrade =>
  isRecordType(trade) && trade.type === ArticleTradeType.Year;
