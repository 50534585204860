import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import type {
  MakeNotNilProperty,
  NotNilProperties,
} from "@karrotmarket-com/type-utility";
import { captureException } from "@sentry/remix";
import { ArticleTradeType } from "__generated__/jampot/alpha-kr";
import {
  ArticleOptionNameEnum,
  RealtyArticleStatusEnum,
  SalesTypeEnum,
} from "__generated__/jampot/alpha-kr";
import { entries, get, isEmpty, isNil } from "lodash";
import { match } from "ts-pattern";
import { z } from "zod";

import {
  isBorrowType,
  isBuyType,
  isMonthType,
  isShortTrade,
  isYearType,
} from "~/services/realty/utils/guard";
import { krServiceHomeUrl } from "~/site-urls";

import type { Address, LdJson, LdJsonObject } from "./common";
import {
  addressSchema,
  geoSchema,
  getAddress,
  getGeo,
  priceCurrencyMap,
} from "./common";

const realtyItemAvailabilityMap = {
  [RealtyArticleStatusEnum.OnGoing]: "https://schema.org/InStock",
  [RealtyArticleStatusEnum.Reserved]: "https://schema.org/OutOfStock",
  [RealtyArticleStatusEnum.Traded]: "https://schema.org/OutOfStock",
};

const schemaTypeMap = {
  [SalesTypeEnum.Apart]: "Apartment",
  [SalesTypeEnum.Officetel]: "SingleFamilyResidence",
  [SalesTypeEnum.OpenOneRoom]: "SingleFamilyResidence",
  [SalesTypeEnum.SplitOneRoom]: "SingleFamilyResidence",
  [SalesTypeEnum.TwoRoom]: "SingleFamilyResidence",
  [SalesTypeEnum.Store]: "Store",
  [SalesTypeEnum.Etc]: "Place",
};

type Agent = {
  id?: string | null;
  name?: string | null;
  telephone?: string | null;
  address?: Address | null;
};

type Trade = {
  type: ArticleTradeType;
  price?: number | null;
  monthlyPay?: number | null;
  monthlyDeposit?: number | null;
  deposit?: number | null;
};

const realtyDetailLdJsonSchema = z.object({
  salesType: z.nativeEnum(SalesTypeEnum),
  status: z.nativeEnum(RealtyArticleStatusEnum),
  name: z.string(),
  description: z.string(),
  image: z.array(z.string()),
  url: z.string(),
  address: addressSchema,
  geo: geoSchema,
  floorLevel: z.number().nullish(),
  floorSize: z.number().nullish(),
  numberOfRooms: z.number().nullish(),
  numberOfBathroomsTotal: z.number().nullish(),
  amenityFeature: z.array(z.nativeEnum(ArticleOptionNameEnum)),
  trades: z.array(
    z.object({
      type: z.nativeEnum(ArticleTradeType),
      price: z.number().nullish(),
      monthlyPay: z.number().nullish(),
      monthlyDeposit: z.number().nullish(),
      deposit: z.number().nullish(),
    }),
  ),
  agent: z
    .object({
      id: z.string().nullish(),
      name: z.string().nullish(),
      telephone: z.string().nullish(),
      address: addressSchema.nullish(),
    })
    .nullable(),
});

type RealtyItemLdJson = z.infer<typeof realtyDetailLdJsonSchema>;

export const realtyDetailPageSchema = (data: unknown) => {
  const parseResult = realtyDetailLdJsonSchema.safeParse(data);

  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
};

export const realtyDetailPage = (
  item: RealtyItemLdJson,
  countryCode: KarrotLocalCountryCode,
  origin: string,
): LdJson[] => {
  const realtyId = item.url;
  const mainEntity: LdJsonObject = {
    "@type": schemaTypeMap[item.salesType],
    "@id": realtyId,
    identifier: item.url,
    name: item.name,
    description: item.description,
    image: item.image,
    address: getAddress(item.address, countryCode),
  };

  const optionalProperties = {
    geo: getGeo(item.geo),
    floorLevel: item.floorLevel,
    floorSize:
      typeof item.floorSize === "number"
        ? {
            "@type": "QuantitativeValue",
            value: item.floorSize,
            unitCode: "MTR",
          }
        : null,
    numberOfRooms: item.numberOfRooms,
    numberOfBathroomsTotal: item.numberOfBathroomsTotal,
    amenityFeature: isEmpty(item.amenityFeature)
      ? null
      : getAmenityFeature(item.amenityFeature),
  };

  for (const [key, value] of entries(optionalProperties)) {
    if (!isNil(value)) {
      mainEntity[key] = value;
    }
  }

  const ldJson = [
    mainEntity,
    getAgentJsonLd(item.agent),
    getOffers({
      trades: item.trades,
      realtyId,
      status: item.status,
    }),
  ].flatMap((value) =>
    isNil(value)
      ? []
      : [
          {
            "script:ld+json": value,
          },
        ],
  );

  ldJson.push({
    "script:ld+json": {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "당근 부동산",
          item: `${origin}${krServiceHomeUrl.REALTY}`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: item.name,
        },
      ],
    },
  });

  return ldJson;
};

const realtyListItemLdJsonSchema = z.object({
  salesType: z.nativeEnum(SalesTypeEnum),
  status: z.nativeEnum(RealtyArticleStatusEnum),
  name: z.string(),
  description: z.string(),
  image: z.array(z.string()),
  url: z.string(),
  address: addressSchema,
});

export const realtyListPageSchema = (data: unknown[]) => {
  const parseResult = data.flatMap((item) => {
    const result = realtyListItemLdJsonSchema.safeParse(item);

    if (result.success) {
      return [result.data];
    }

    return [];
  });

  if (isEmpty(parseResult)) {
    return null;
  }

  return parseResult;
};

type RealtyListPageLdJsonParams = {
  items: z.infer<typeof realtyListItemLdJsonSchema>[];
};

export const realtyListPage = (
  { items }: RealtyListPageLdJsonParams,
  countryCode: KarrotLocalCountryCode,
): LdJson => {
  return {
    "script:ld+json": {
      "@context": "https://schema.org",
      "@type": "ItemList",
      numberOfItems: items.length,
      itemListElement: items.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@context": "https://schema.org",
          "@type": schemaTypeMap[item.salesType],
          identifier: item.url,
          name: item.name,
          description: item.description,
          image: item.image,
          address: getAddress(item.address, countryCode),
        },
      })),
    },
  };
};

/**
 * @see https://developers.google.com/search/docs/appearance/structured-data/vacation-rental?hl=ko
 */
const amenityMap = {
  [ArticleOptionNameEnum.Aircon]: ["ac", true],
  [ArticleOptionNameEnum.Elevator]: ["elevator", true],
  [ArticleOptionNameEnum.ElecRange]: ["microwave", true],
  [ArticleOptionNameEnum.GasRange]: ["ovenStove", true],
  [ArticleOptionNameEnum.Induction]: ["ovenStove", true],
  [ArticleOptionNameEnum.Parking]: ["parkingType", "Free"],
  [ArticleOptionNameEnum.Pet]: ["petsAllowed", true],
  [ArticleOptionNameEnum.Washer]: ["washerDryer", true],
};

const getAmenityFeature = (amenityFeature: ArticleOptionNameEnum[]) => {
  return amenityFeature.flatMap((amenity) => {
    const property = get(amenityMap, amenity);

    if (isNil(property)) {
      return [];
    }

    const [name, value] = property;

    return {
      "@type": "LocationFeatureSpecification",
      name,
      value,
    };
  });
};

const isValidAgent = (
  agent: Agent | null,
): agent is NotNilProperties<Omit<Agent, "address">> & {
  address: MakeNotNilProperty<Address, "streetAddress">;
} => {
  if (isNil(agent)) {
    return false;
  }

  const agentResult = Object.values(agent).every((value) => !isNil(value));
  const addressResult = typeof agent.address?.streetAddress === "string";

  return addressResult && agentResult;
};

const getAgentJsonLd = (agent: Agent | null): LdJsonObject | null => {
  if (!isValidAgent(agent)) {
    return null;
  }

  const address = getAddress(agent.address, KarrotLocalCountryCode.KR);

  return {
    "@type": "RealEstateAgent",
    "@id": agent.id,
    name: agent.name,
    telephone: agent.telephone,
    address,
  };
};

type GetOffersParams = {
  trades: Trade[];
  realtyId: string;
  status: RealtyArticleStatusEnum;
};
const getOffers = ({
  trades,
  realtyId,
  status,
}: GetOffersParams): LdJsonObject | null => {
  const offers = trades.map((trade) => {
    return match(trade)
      .when(isBorrowType, (value) => ({
        "@type": "Offer",
        "@id": realtyId,
        businessFunction: "http://purl.org/goodrelations/v1#LeaseOut",
        price: value.deposit * 10000,
        availability: realtyItemAvailabilityMap[status],
      }))
      .when(isMonthType, (value) => ({
        "@type": "Offer",
        "@id": realtyId,
        businessFunction: "http://purl.org/goodrelations/v1#LeaseOut",
        price: value.monthlyPay * 10000,
        availability: realtyItemAvailabilityMap[status],
      }))
      .when(isShortTrade, (value) => ({
        "@type": "Offer",
        businessFunction: "http://purl.org/goodrelations/v1#LeaseOut",
        price: value.deposit * 10000,
        availability: realtyItemAvailabilityMap[status],
      }))
      .when(isBuyType, (value) => ({
        "@type": "Offer",
        businessFunction: "http://purl.org/goodrelations/v1#Sell",
        price: value.price * 10000,
        availability: realtyItemAvailabilityMap[status],
      }))
      .when(isYearType, (value) => ({
        "@type": "Offer",
        businessFunction: "http://purl.org/goodrelations/v1#LeaseOut",
        price: value.yearlyPay * 10000,
        availability: realtyItemAvailabilityMap[status],
      }))
      .otherwise(() => {
        captureException("Realty trade type is not matched");
        return {};
      });
  });

  if (isEmpty(offers)) {
    return null;
  }

  return {
    "@type": "AggregateOffer",
    priceCurrency: priceCurrencyMap[KarrotLocalCountryCode.KR],
    offers,
  };
};
