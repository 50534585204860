/**
 * @fileoverview
 * 모임 게시글은 특성상 json ld에 딱 맞는 타입이 없어서, 모임과 관련한 이벤트의 json ld만 제공해요.
 * Event 타입의 검색은 현재(2024-08-28 KST) 한국에서 지원하지 않지만 추후 지원될 수 있어요.
 * https://developers.google.com/search/docs/appearance/structured-data/event?hl=ko#region-availability
 *
 * 하나의 이벤트에 집중하는 페이지만 지원하지 때문에 열려있는 이벤트 중 참석할 수 있거나 최신의 이벤트 하나만 제공해요.
 */

import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { entries, isNil } from "lodash";
import { CommunityGroupMeetupProgressStatus } from "__generated__/jampot/alpha-kr";
import { z } from "zod";

import { krServiceHomeUrl } from "~/site-urls";

import type { LdJson, LdJsonObject } from "./common";
import { addressSchema, geoSchema, getAddress, getGeo } from "./common";

const eventStatusMap = {
  [CommunityGroupMeetupProgressStatus.Closed]:
    "https://schema.org/EventCancelled",
  [CommunityGroupMeetupProgressStatus.Opened]:
    "https://schema.org/EventScheduled",
  [CommunityGroupMeetupProgressStatus.FullyRecruited]:
    "https://schema.org/EventScheduled",
};

const groupDetailLdJsonSchema = z.object({
  address: addressSchema
    .required({
      streetAddress: true,
    })
    .extend({
      name: z.string(),
    }),
  geo: geoSchema,
  name: z.string(),
  startDate: z.string(),
  status: z.nativeEnum(CommunityGroupMeetupProgressStatus),
  image: z.string().optional(),
});

export const groupDetailPageSchema = (data: unknown) => {
  const parseResult = groupDetailLdJsonSchema.safeParse(data);

  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
};

export const groupDetailPage = (
  name: string,
  item: unknown,
  countryCode: KarrotLocalCountryCode,
  origin: string,
): LdJson[] => {
  const result: LdJson[] = [
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "당근 동네모임",
            item: `${origin}${krServiceHomeUrl.GROUP}`,
          },
          {
            "@type": "ListItem",
            position: 2,
            name,
          },
        ],
      },
    },
  ];

  const validateResult = groupDetailPageSchema(item);

  if (isNil(validateResult)) {
    return result;
  }

  const ldJson: LdJsonObject = {
    location: {
      "@type": "Place",
      name: validateResult.address.name,
      address: getAddress(validateResult.address, countryCode),
    },
    name: validateResult.name,
    startDate: validateResult.startDate,
    eventStatus: eventStatusMap[validateResult.status],
  };

  const optionalProperties = {
    geo: getGeo(validateResult.geo),
    image: validateResult.image,
  };

  for (const [key, value] of entries(optionalProperties)) {
    if (!isNil(value)) {
      ldJson[key] = value;
    }
  }

  result.push({
    "script:ld+json": ldJson,
  });

  return result;
};
