import { isEmpty, isNil, sortBy } from "lodash";
import { z } from "zod";

import { krServiceHomeUrl } from "~/site-urls";

import type { LdJson } from "./common";

const commentsSchema = z.array(
  z.object({
    text: z.string(),
    upvoteCount: z.number(),
    dateCreated: z.string(),
    authorName: z.string(),
    url: z.string(),
  }),
);

type Comments = z.infer<typeof commentsSchema>;

const communityDetailLdJsonSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  postAuthor: z.object({
    name: z.string(),
    url: z.string(),
  }),
  url: z.string(),
  dateCreated: z.string(),
  dateModified: z.string(),
  datePublished: z.string(),
  upvoteCount: z.number(),
  image: z.union([z.string(), z.array(z.string())]),
  comments: commentsSchema.nullable(),
  commentsCount: z.number(),
  logo: z.string(),
});

export const communityDetailPageSchema = (data: unknown) => {
  const parseResult = communityDetailLdJsonSchema.safeParse(data);

  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
};

export const communityDetailPage = (
  name: string,
  item: unknown,
  origin: string,
): LdJson[] => {
  const result: LdJson[] = [
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "당근 동네 소식",
            item: `${origin}${krServiceHomeUrl.COMMUNITY}`,
          },
          {
            "@type": "ListItem",
            position: 2,
            name,
          },
        ],
      },
    },
  ];

  const validateResult = communityDetailPageSchema(item);

  if (isNil(validateResult)) {
    return result;
  }

  result.push(
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "WebPage",
        mainEntity: {
          "@type": "Article",
          headline: validateResult.title,
          description: validateResult.description ?? "",
          dateModified: validateResult.dateModified,
          datePublished: validateResult.datePublished,
          url: validateResult.url,
          author: {
            "@type": "Person",
            name: validateResult.postAuthor.name,
            url: validateResult.postAuthor.url,
          },
          publisher: {
            "@type": "Organization",
            name: "당근",
            logo: {
              "@type": "ImageObject",
              url: validateResult.logo,
            },
          },
        },
      },
    },
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "SocialMediaPosting",
        dateCreated: validateResult.dateCreated,
        datePublished: validateResult.datePublished,
        dateModified: validateResult.dateModified,
        headline: validateResult.title,
        commentCount: validateResult.commentsCount,
        sharedContent: {
          "@type": "WebPage",
          headline: validateResult.title,
          url: validateResult.url,
        },
        author: {
          "@type": "Person",
          name: validateResult.postAuthor.name,
          url: validateResult.postAuthor.url,
        },
      },
    },
  );

  if (!isEmpty(validateResult.image)) {
    result.forEach((ldJson) => {
      ldJson["script:ld+json"].image = validateResult.image;
    });
  }

  if (
    Array.isArray(validateResult.comments) &&
    !isEmpty(validateResult.comments)
  ) {
    const acceptedAnswer = getAcceptedAnswer(validateResult.comments);

    result.push({
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "QAPage",
        mainEntity: {
          "@type": "Question",
          name: validateResult.title,
          text: validateResult.description ?? "",
          answerCount: validateResult.comments.length,
          upvoteCount: validateResult.upvoteCount,
          dateCreated: validateResult.dateCreated,
          author: {
            "@type": "Person",
            name: validateResult.postAuthor.name,
            url: validateResult.postAuthor.url,
          },
          suggestedAnswer: validateResult.comments.map((comment) => ({
            "@type": "Answer",
            text: comment.text,
            upvoteCount: comment.upvoteCount,
            dateCreated: comment.dateCreated,
            url: comment.url,
            author: {
              "@type": "Person",
              name: comment.authorName,
            },
          })),
          acceptedAnswer,
        },
      },
    });
  }

  return result;
};

const getAcceptedAnswer = (comments: Comments) => {
  const recentlyComment = comments[0];
  const isSameUpvoteCount = Object.values(comments).every(
    (comment) => comment.upvoteCount === recentlyComment.upvoteCount,
  );

  if (isSameUpvoteCount) {
    return {
      "@type": "Answer",
      text: recentlyComment.text,
      upvoteCount: recentlyComment.upvoteCount,
      dateCreated: recentlyComment.dateCreated,
      url: recentlyComment.url,
      author: {
        "@type": "Person",
        name: recentlyComment.authorName,
      },
    };
  }

  const highestUpvoteCountComment = sortBy(comments, [
    "upvoteCount",
  ]).reverse()[0];

  return {
    "@type": "Answer",
    text: highestUpvoteCountComment.text,
    upvoteCount: highestUpvoteCountComment.upvoteCount,
    dateCreated: highestUpvoteCountComment.dateCreated,
    url: highestUpvoteCountComment.url,
    author: {
      "@type": "Person",
      name: highestUpvoteCountComment.authorName,
    },
  };
};

const communityListItemLdJsonSchema = z.object({
  title: z.string(),
  description: z.string(),
  url: z.string(),
  dateCreated: z.string(),
  dateModified: z.string(),
  datePublished: z.string(),
  upvoteCount: z.number(),
  postAuthor: z.object({
    name: z.string(),
    url: z.string(),
  }),
  image: z.union([z.string(), z.array(z.string())]),
  commentsCount: z.number(),
  logo: z.string(),
});

export const communityListPageSchema = (data: unknown[]) => {
  const parseResult = data.flatMap((item) => {
    const result = communityListItemLdJsonSchema.safeParse(item);

    if (result.success) {
      return [result.data];
    }

    return [];
  });

  if (isEmpty(parseResult)) {
    return null;
  }

  return parseResult;
};

type CommunityListLdPageParams = z.infer<
  typeof communityListItemLdJsonSchema
>[];

export const communityListPage = (
  items: CommunityListLdPageParams,
): LdJson[] => {
  return [
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "ItemList",
        numberOfItems: items.length,
        itemListElement: items.map((item, index) => ({
          "@type": "ListItem",
          position: index + 1,
          item: {
            "@type": "Article",
            headline: item.title,
            description: item.description ?? "",
            dateModified: item.dateModified,
            datePublished: item.datePublished,
            url: item.url,
            author: {
              "@type": "Person",
              name: item.postAuthor.name,
              url: item.postAuthor.url,
            },
            publisher: {
              "@type": "Organization",
              name: "당근",
              logo: {
                "@type": "ImageObject",
                url: item.logo,
              },
            },
          },
        })),
      },
    },
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "ItemList",
        numberOfItems: items.length,
        itemListElement: items.map((item, index) => ({
          "@type": "ListItem",
          position: index + 1,
          item: {
            "@type": "SocialMediaPosting",
            dateCreated: item.dateCreated,
            datePublished: item.datePublished,
            dateModified: item.dateModified,
            headline: item.title,
            commentCount: item.commentsCount,
            sharedContent: {
              "@type": "WebPage",
              headline: item.title,
              url: item.url,
            },
          },
        })),
      },
    },
  ];
};
