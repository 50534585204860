import { businessPost, businessPostPageSchema } from "./business-post";
import {
  buySellAllPage,
  buySellDetailPage,
  buySellDetailPageSchema,
  buySellListPageSchema,
} from "./buy-sell";
import { breadcrumbList } from "./common";
import {
  communityDetailPage,
  communityDetailPageSchema,
  communityListPage,
  communityListPageSchema,
} from "./community";
import { groupDetailPage, groupDetailPageSchema } from "./group";
import { homePage } from "./home";
import {
  jobDetailPage,
  jobDetailPageSchema,
  jobListPage,
  jobListPageSchema,
} from "./jobs";
import {
  localProfileDetailPage,
  localProfileDetailPageSchema,
  localProfileListPage,
  localProfileListPageSchema,
} from "./local-profile";
import {
  realtyDetailPage,
  realtyDetailPageSchema,
  realtyListPage,
  realtyListPageSchema,
} from "./realty";

export const ldJson = {
  homePage,
  buySellAllPage,
  buySellDetailPage,
  jobDetailPage,
  jobListPage,
  localProfileDetailPage,
  localProfileListPage,
  realtyDetailPage,
  realtyListPage,
  communityDetailPage,
  communityListPage,
  groupDetailPage,
  breadcrumbList,
  businessPost,
};

export const ldJsonValidator = {
  communityDetailPage: communityDetailPageSchema,
  communityListPage: communityListPageSchema,
  groupDetailPage: groupDetailPageSchema,
  localProfileDetailPage: localProfileDetailPageSchema,
  localProfileListPage: localProfileListPageSchema,
  jobDetailPage: jobDetailPageSchema,
  jobListPage: jobListPageSchema,
  realtyDetailPage: realtyDetailPageSchema,
  realtyListPage: realtyListPageSchema,
  buySellDetailPage: buySellDetailPageSchema,
  buySellListPage: buySellListPageSchema,
  businessPostPage: businessPostPageSchema,
};
