import type { LdJson } from "./common";

type HomePageLdJsonParams = {
  url: string;
  name: string;
  searchURL: string;
};
export const homePage = ({
  url,
  name,
  searchURL,
}: HomePageLdJsonParams): LdJson => ({
  "script:ld+json": {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name,
    url,
    /**
     * @see https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox?hl=ko#guidelines
     */
    potentialAction: [
      {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${url}${searchURL}?search={search_string}`,
        },
        "query-input": "required name=search_string",
      },
    ],
  },
});
