import type { Exact } from "__generated__/jampot/alpha-kr";
import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { isString, pickBy } from "lodash";
import { z } from "zod";

type LdJsonArray = LdJsonValue[] | readonly LdJsonValue[];
type LdJsonPrimitive = string | number | boolean | null;
type LdJsonValue = LdJsonPrimitive | LdJsonObject | LdJsonArray;
export type LdJsonObject = {
  [Key in string]: LdJsonValue;
} & {
  [Key in string]?: LdJsonValue | undefined;
};

export type LdJson = Record<"script:ld+json", LdJsonObject>;

export const addressSchema = z.object({
  streetAddress: z
    .string()
    .nullish()
    .describe("실제 주소. e.g.) 서울 서초구 서초중앙로22길 25"),
  addressLocality: z.string().nullish().describe("Region.name2. e.g.) 서초구"),
  addressRegion: z
    .string()
    .nullish()
    .describe("Region.name1. e.g.) 서울특별시"),
});

export type Address = Exact<z.infer<typeof addressSchema>>;

export const getAddress = (
  address: Address,
  countryCode: KarrotLocalCountryCode,
): LdJsonObject => {
  const location = pickBy(
    {
      streetAddress: address.streetAddress,
      addressLocality: address.addressLocality,
      addressRegion: address.addressRegion,
    },
    isString,
  );

  return {
    "@type": "PostalAddress",
    addressCountry: countryCode.toUpperCase(),
    ...location,
  };
};

export type AggregateRating = {
  value: number;
  reviewCount: number;
};
export const getAggregateRating = (aggregateRating: {
  value: number;
  reviewCount: number;
}) => {
  if (aggregateRating.value <= 0 || aggregateRating.reviewCount <= 0) {
    return null;
  }

  return {
    "@type": "AggregateRating",
    ratingValue: aggregateRating.value.toFixed(1),
    reviewCount: aggregateRating.reviewCount,
  };
};

export const geoSchema = z
  .object({
    latitude: z.number(),
    longitude: z.number(),
  })
  .partial();

export type Geo = z.infer<typeof geoSchema>;

export const getGeo = (geo: Geo) => {
  if (geo.latitude == null || geo.longitude == null) {
    return null;
  }

  return {
    "@type": "GeoCoordinates",
    latitude: geo.latitude,
    longitude: geo.longitude,
  };
};

export const priceCurrencyMap = {
  [KarrotLocalCountryCode.KR]: "KRW",
  [KarrotLocalCountryCode.JP]: "JPY",
  [KarrotLocalCountryCode.CA]: "CAD",
  [KarrotLocalCountryCode.US]: "USD",
  [KarrotLocalCountryCode.UK]: "GBP",
};

export function breadcrumbList(
  breadcrumbItems: { url?: string; label: string }[],
) {
  if (breadcrumbItems.length < 2) {
    return null;
  }

  return {
    "script:ld+json": {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbItems.map(({ url, label }, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: label,
        ...(url ? { item: url } : {}),
      })),
    },
  };
}
